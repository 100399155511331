import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isShow: false,
    //wxConfig: {},
    openId: "",
    unionId: "",
    appId: "wx687a0c10560aa623",
    ifPayed: false,
  },
  getters: {},
  mutations: {
    // 展示loading的方法
    showLoading(state) {
      state.isShow = true;
    },
    // 隐藏loading的方法
    hideLoading(state) {
      state.isShow = false;
    },
    // SET_WXConfig(state, value) {
    //   state.wxConfig = value;
    // },
    SET_OPENID(state, value) {
      state.openId = value;
    },
    SET_UNIONID(state, value) {
      state.unionId = value;
    },
    SET_PAYED(state, value) {
      state.ifPayed = value;
    },
  },
  actions: {},
  modules: {},
});
