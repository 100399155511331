import req from "@/utils/request";

export const getTypeList = (params) => {
  return req({
    url: '/selfCurrType/typeList',
    method: 'post',
    data:params
  });
};
export const getCurrList = (params) => {
  return req({
    url: '/selfCurr/currList',
    method: 'post',
    data:params
  });
};

export const getInfo = (params) => {
  return req({
    url: "/user/getByWxId",
    method: "post",
    data: params,
  });
};
export const getVideoUrl = (params) => {
  return req({
    url: "/selfCurr/getUrl",
    method: "post",
    data: params,
  });
};