import Vue from "vue";
import VueRouter from "vue-router";

import PAY from "../pages/pay/index";
import HOME from "../pages/Home/home";
import SSO from "../pages/sso/index";
import FOLLOW from "../pages/Follow/follow";
import LESSON from "../pages/Lesson/lesson";
import VIDEO from '../pages/Video/video'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/follow",
  },
  {
    path: "/home",
    name: "home",
    component: HOME,
  },
  {
    path: "/sso",
    name: "sso",
    component: SSO,
  },
  {
    path: "/pay",
    name: "pay",
    component: PAY,
  },

  {
    path: "/follow",
    name: "follow",
    component: FOLLOW,
  },
  {
    path: "/lesson",
    name: "lesson",
    component: LESSON,
    meta:{
      keepAlive:true
    }
  },
  {
    path: "/video",
    name: "video",
    component: VIDEO,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   console.log("ss: ", to, from, next);
//   next();
// });
export default router;
