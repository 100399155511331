import req from "@/utils/request";

export const getData = (params) => {
  return req({
    url: '/data/data',
    method: 'post',
  });
};

export const getWxurl = (params) => {
  return req({
    url: '/applet/getScheme',
    method: 'post',
    data: params
  });
};

