<template>
  <div class="lesson">
    <div class="header">
      <div class="header-title">{{ empName }}同学,专属解锁课程</div>
    </div>
    <div class="main">
      <van-tabs
        v-model="active"
        @click="onClick"
        color="#282828"
        background="#e9eef2"
        line-height="0"
        title-inactive-color="#999"
        title-active-color="#183fb7"
      >
        <van-tab
          v-for="item in tabsList"
          :key="item.id"
          :title="item.name"
          title-style=" font-weight: 600; letter-spacing: 2px"
        >
          <Tabs
            :tabListF="item.children"
            :sidelistF="item.children[0].children"
            :tabsIdF="item.id"
            :activeOptionF="item.children[0].id"
          />
        </van-tab>
      </van-tabs>
    </div>
    <div class="fixed">
      <div class="consult" @touchstart="toConsult()"></div>
      <div class="service" @touchstart="toService()"></div>
    </div>
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
import { getTypeList, getInfo } from "@/api/lesson.js";

import Tabs from "./components/tabs.vue";
export default {
  components: { Tabs },
  data() {
    return {
      active: 0,
      tabsId: 1,
      tabsList: [],
      empName: "", //用户名称
      unionId: "",
    };
  },
  created() {
    this.getInfo();
    this.getTypeList();
  },
  mounted() {
    if (!this.$store.state.ifPayed) {
      this.$router.replace({ path: "/home" });
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log("to", to);
  //   console.log("from", from);
  //   console.log("next", next);
  //   if (from.path == "/video") {
  //     next((vm) => {
  //       // 通过 vm 访问 Vue 实例，可以使用 vm.$store 访问 Vuex store
  //       console.log(vm.$store);
  //       vm.$store.commit("SET_ISFORMVIDEO", true);
  //     });
  //   }
  //   next();
  // },
  methods: {
    //切换tabs
    onClick(name, title) {
      console.log(name);
      // this.getTypeList();
    },

    // 在子组件中触发的事件处理程序，用于更新父组件中的 activeOptionF 数据
    // updateActiveOption(tabId, newActiveOption) {
    //   console.log("tabId, newActiveOption", tabId, newActiveOption);
    //   const tab = this.tabsList.find((item) => {
    //     if (item.id === tabId) {
    //       item.children[0].id = newActiveOption;
    //     }
    //   });
    // },

    //打开咨询
    toConsult() {
      console.log("咨询");
      try {
        window.open(
          "https://work.weixin.qq.com/kfid/kfc101d3f301cdecc41?enc_scene=ENCAtr3ykYFJGhedaUktjcPjuVtJepNhDf23pz3bmpY1NNZ",
          "_self"
        );
      } catch (error) {
        console.log(error);
      }
    },
    //打开客服
    toService() {
      console.log("客服");
      try {
        window.open(
          "https://work.weixin.qq.com/kfid/kfc101d3f301cdecc41?enc_scene=ENCAtr3ykYFJGhedaUktjcPjuVtJepNhDf23pz3bmpY1NNZ",
          "_self"
        );
      } catch (error) {
        console.log(error);
      }
    },
    //获取用户信息
    getInfo() {
      this.unionId = this.$store.state.unionId;
      getInfo({ unionId: this.unionId })
        .then((res) => {
          this.empName = res.data.userName;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取页面初始化数据
    getTypeList() {
      getTypeList({ id: 0 })
        .then((res) => {
          if (res.code === 10000) {
            console.log(res);
            this.tabsList = res.data;
            console.log(this.tabsList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  font-size: 0.14rem;
  background-color: #fff;
  height: 100vh;
  .header {
    position: relative;
    width: 100%;
    background-image: url("~@/assets/images/lesson/1e7b78607af709dbd90e5b88866d947.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 41%;
    overflow: hidden;
    .header-title {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%);
      margin-left: 5px;
      color: #0b229b;
      font-weight: bold;
    }
  }

  .fixed {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    bottom: 0.8rem;
    width: 0.58rem;
    height: 1.1rem;
    .consult {
      width: 100%;
      flex: 1;
      background-image: url("~@/assets/images/lesson/咨询@2x.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin-bottom: 0.2rem;
    }
    .service {
      width: 100%;
      flex: 1;
      background-image: url("~@/assets/images/lesson/客服@2x.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
</style>
<style lang="scss">
.van-tabs__wrap {
  .van-tab--active {
    background-color: #fff !important; /* 设置选中后的背景色 */
    // color: #0b229b; /* 设置文本颜色 */
  }
}
</style>
