<template>
  <div class="video">
    <video ref="player" class="plyr" controls>
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </div>
</template>

<script>
// import "plyr/dist/plyr.css";
// import Plyr from "plyr";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      videoUrl: "",
    };
  },
  created() {
    wx.hideOptionMenu();
    this.videoUrl = this.$route.query.url;
  },
  mounted() {
    // const player = new Plyr(this.$refs.player, {
    //   // 可选的配置选项
    // });
  },
};
</script>

<style lang="scss" scoped>
.video {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  background-color: #000;
  height: 100vh;
  .plyr {
    width: 100%;
  }
}
</style>
