import request from "@/utils/request";

export const getUserInfoByCode = (params) => {
  return request({
    url: "/api/wx-pay/getCode",
    method: "get",
    params,
  });
};

export const getConfig = (params) => {
  return request({
    url: "/api/wx-pay/getConfig",
    method: "get",
    params,
  });
};

export const getRandom = (params) => {
  return request({
    url: "/api/wx-pay/round",
    method: "post",
    data: params,
  });
};

// 下单
export const getOrder = (params) => {
  return request({
    url: "/api/wx-pay/jsapi",
    method: "post",
    data: params,
  });
};

export const getState = (params) => {
  return request({
    url: "/official/state",
    method: "post",
    data: params,
  });
};

export const getInfo = (params) => {
  return request({
    url: "/user/getByWxId",
    method: "post",
    data: params,
  });
};

export const notifyPaySuccess = (params) => {
  return request({
    url: "/api/wx-pay/jsapi/notify",
    method: "post",
    data: params,
  });
};

// export const getticket = (params) => {
//   return request({
//     url: "https://api.weixin.qq.com/cgi-bin/ticket/getticket",
//     method: "get",
//     params,
//   });
// };
