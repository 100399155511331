<template>
  <div></div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getUserInfoByCode, getConfig, getState } from "@/api/pay";
export default {
  data() {
    return {
      openId: "",
      unionId: "",
      config: {},
      redirect_uri: "https://www.zsrdself.top/sso", // 上线前做修改,以及store里的appId
    };
  },
  async mounted() {
    await this.getOpenId();
    await this.getState();
  },
  methods: {
    getUrlParams() {
      let str = window.location.search;
      if (str && str.includes("?") && str.includes("=")) {
        let obj = {};
        str = str.slice(1);
        if (str.includes("&")) {
          let arr = str.split("&");
          for (let i = 0; i < arr.length; i++) {
            let one = arr[i].split("=");
            obj[one[0]] = one[1];
          }
        } else {
          let one = str.split("=");
          obj[one[0]] = one[1];
        }
        return obj;
      }
    },
    // 获取url
    getWxUrl() {
      let backUrl = encodeURIComponent(this.redirect_uri);
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.$store.state.appId}&redirect_uri=${backUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      return url;
    },

    async initWX(rel) {
      // 后台返回的配置信息
      this.config = rel;
      // this.$store.commit("SET_WXConfig", this.config);
      const jsArr = ["chooseWXPay", "hideOptionMenu"];
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.config.appId, // 必填，公众号的唯一标识
        timestamp: this.config.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.config.nonceStr, // 必填，生成签名的随机串
        signature: this.config.signature, // 必填，签名
        jsApiList: jsArr, // 必填，需要使用的JS接口列表
      });

      const that = this;

      wx.ready(function () {
        // 验证接口
        wx.checkJsApi({
          jsApiList: jsArr,
          success: function () {
            that.goPage("/pay", that);
          },
        });
      });

      // 通过error接口处理失败验证
      wx.error(function (a, b) {
        console.error("error", a, b);
      });
    },
    async getState() {
      let result = await getState({
        openId: this.openId,
        unionId: this.unionId,
      });
      this.$store.commit(
        "SET_PAYED",
        result.data.type && result.data.type === "1" ? true : false
      );
      if (result.data.type === "1") {
        // 已支付
        this.goPage("/lesson", this);
      } else if (result.data.type === "2") {
        // 未支付
        this.initPage();
      } else {
        // 未在小程序填写个人信息，让其进入小程序
        this.goPage("/home", this);
      }
    },
    async getOpenId() {
      let paramsObj = this.getUrlParams();

      if (!paramsObj || !paramsObj.code) {
        // 1.第一次进入这个页面。初次页面，需要调起授权页面
        let url = this.getWxUrl();
        window.location.href = url;
      } else {
        try {
          let result = await getUserInfoByCode({ code: paramsObj.code });

          const { openId, unionId } = result.data;
          this.openId = openId;
          this.unionId = unionId;
          this.$store.commit("SET_OPENID", openId);
          this.$store.commit("SET_UNIONID", unionId);
        } catch (error) {
          console.error("服务器报错：error:", error);
          alert("服务器出错");
        }
      }
    },
    goPage(page, that) {
      that.$router.replace({ path: page });
    },
    async initPage() {
      // 初始化微信js-api
      try {
        let loc =
          window.location.protocol + "//" + window.location.host + "/pay/";
        let rel = await getConfig({ url: loc });
        // 初始化微信配置
        this.initWX(rel.data);
      } catch (error) {
        console.error("error", error);
        this.$alert("服务器报错", "错误", {
          type: "error",
        });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.layout {
  font-size: 20px;
  .pay-btn {
    font-size: 16px;
    padding: 5px;
  }
}
</style>
