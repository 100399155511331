<template>
  <div class="follow">
    <div class="photo"></div>
    <div class="main">
      <img src="@/assets/images/wecaht/wxtp_20240329162319.png" alt="" />
    </div>
    <div class="number">
      已有<span class="numbers">{{ objData.numbers }}</span
      >名同学报考成功
    </div>
    <ul class="avatar">
      <li v-for="(item, index) in pArr" :key="index">
        <img :src="require(`@/assets/images/touxiang/${item}`)" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
import { getData } from "@/api/home.js";

export default {
  data() {
    return {
      objData: { numbers: 31000 },
      pArr: [
        "02bfaed56378f9842efc5e73c00548d0.jpeg",
        "9ce2e003970c4eda0c5762c1259b3674.jpeg",
        "19af882f6be3c7d47555ff06b1a1f5e6.png",
        "256a978851926f40feab04308d9df1a1.jpeg",
        "664154965beda99f68452c1112622bad.jpeg",
        "b07867bb3f53af2f422febb5b4ecf41c.jpeg",
        "c91ad66557a305591384fb06a7ecdda4.jpeg",
        "cbdba8d9e897a5cbdcd54075dda46291.jpeg",
        "ede4e402383b5bdaeac93ade0a036bca.jpeg",
      ],
    };
  },
  created() {
    //设置报考人数
    let currentTime = new Date().getTime();
    let specificDate = new Date("2024-04-08T12:00:00").getTime();
    let addTime = Math.floor((currentTime - specificDate) / (1000 * 60 * 2.4));
    this.objData.numbers = Number(this.objData.numbers) + addTime;
    //获取数据
    // this.getData();
  },
  methods: {
    //获取数据请求
    getData() {
      console.log("111");
      getData()
        .then((res) => {
          if (true) {
            console.log(" res: ", res);
            this.objData = JSON.parse(JSON.stringify(res.data[0]));
            //设置报考人数
            let currentTime = new Date().getTime();
            let specificDate = new Date("2024-03-18T12:00:00").getTime();
            let addTime = Math.floor(
              (currentTime - specificDate) / (1000 * 60 * 2.4)
            );
            console.log(currentTime);
            console.log(specificDate);
            console.log(addTime);
            this.objData.numbers = Number(this.objData.numbers) + addTime;
          }
        })
        .catch((err) => {
          console.log(" err: ", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.follow {
  //   position: relative;
  height: 100vh;
  width: 100%;
  font-size: 14px;
  background-image: url("~@/assets/images/wecaht/beijing1111.png");
  background-size: cover;
  //background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 161%;
  box-sizing: border-box;
  overflow: hidden;
  .photo {
    height: 1.12rem;
    margin-top: 0.25rem;
    background-image: url("~@/assets/images/wecaht/tu1.png");
    background-size: contain;
    //background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .main {
    position: relative;
    // position: absolute;
    // top: 20vh;
    // left: 50%;
    // transform: translate(-50%);
    // width: 326px;
    // height: 294px;
    width: 3.26rem;
    height: 2.94rem;
    // padding-bottom: 90.2%;
    background-image: url("~@/assets/images/wecaht/kuang.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    margin: -0.15rem auto 0.9rem;
    img {
      top: 1.4rem;
      // top: 140px;
      left: 0.36rem;
      position: absolute;
      width: 1.04rem;
      // width: 104px;
      height: 1.07rem;
      // height: 107.5px;
    }
  }
  .number {
    margin: 0 auto 0.2rem;
    color: #fff;
    white-space: nowrap;
    // font-size: 0.3rem;
    letter-spacing: 2px;
    .numbers {
      font-size: 0.16rem;
      color: #ffad77;
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
        1px 1px 0 white;
    }
  }
  .avatar {
    margin: 0 auto;
    width: 2.9rem;
    height: 0.3rem;
    display: flex;
    justify-content: space-between;
    li {
      width: 0.29rem;
      height: 100%;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>